
export interface IInsuranceStateModel {
    loading: boolean;
    busy: boolean;
  current: InsuranceType | null;
  }

export type InsuranceType =  {

  }
