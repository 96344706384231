import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Select, Store} from "@ngxs/store";
import {GeotabActions, GeotabState, GeoTabTelematicType, GeotabType} from "@states/geotab";
import {filter, Observable, Subject, take, takeUntil, tap} from "rxjs";
import {BooleanInput, coerceBooleanProperty} from "@angular/cdk/coercion";
import {GeotabTelematicDialogService} from "../telematic";

@Component({
  selector: 'geo-tab-lease-viewer',
  templateUrl: 'geo-tab-lease-viewer.component.html',
  styleUrls: [`geo-tab-lease-viewer.component.scss`]
})
export class GeoTabLeaseViewerComponent implements OnInit, OnDestroy {
  private diagnosticDialogVisible = false;
  private geoTabIconAsLink = false;
  private loadTelematicList = false;
  @Select(GeotabState.getLatestGeoTab) geoTabLatest$!: Observable<Array<GeotabType>>;
  @Select(GeotabState.HasTelematics) hasTelematics$!: Observable<boolean>;
  @Select(GeotabState.getTelematics) telematics$!: Observable<Array<GeoTabTelematicType>>;
  @Select(GeotabState.getLastDaysCalculation) lastDayCalculations$!: Observable<{ [key: string]: number }>;
  @Input() leaseId!: string;

  @Input() set loadTelematics(value: BooleanInput) {
    this.loadTelematicList = coerceBooleanProperty(value);
  }

  get loadTelematics() {
    return this.loadTelematicList;
  }

  private unsubscribe$ = new Subject();
  calculation: number | null = null;

  @Input() set displayDiagnosticsDialog(value: BooleanInput) {
    this.diagnosticDialogVisible = coerceBooleanProperty(value)
  }

  get displayDiagnosticsDialog() {
    return this.diagnosticDialogVisible;
  }

  @Input() set iconAsLink(value: BooleanInput) {
    this.geoTabIconAsLink = coerceBooleanProperty(value)
  }

  get iconAsLink() {
    return this.geoTabIconAsLink;
  }

  geoTabDetail: GeotabType | null = null;
  calc$ = this.lastDayCalculations$.pipe(
    filter(x => x !== null),
    takeUntil(this.unsubscribe$),
    tap((response) => {
        this.calculation = response[this.leaseId] || null;
      }
    )
  ).subscribe()

  constructor(
    private readonly store: Store,
    private readonly telematicDialogService: GeotabTelematicDialogService
  ) {
  }

  ngOnInit() {
    this.geoTabLatest$.pipe(
      filter(x => x.length > 0),
      take(1),
      tap((response) => {
          this.geoTabDetail = response.find(x => x.leaseId === this.leaseId) || null;
          if (this.geoTabDetail?.vehicleIdentificationNumber) {
            this.applyTelematic(this.geoTabDetail.vehicleIdentificationNumber);
          }
        }
      )
    ).subscribe()

    if (this.leaseId) {
      this.store.dispatch([
        new GeotabActions.LoadLeaseLastDayCalculation(this.leaseId)
      ]);

    }
  }

  applyTelematic(vin: string) {
    if (this.loadTelematics) {
      this.store.dispatch(new GeotabActions.LoadTelematic(vin));
    }
  }

  displayTelematics(items: Array<GeoTabTelematicType>) {
    this.telematicDialogService.OnOpen({ title: 'GeoTab Telematics', items: items });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }


}
