
<ng-container *ngIf="items$ | async as items">

  <mat-table class="location-table" [dataSource]="items">

    <ng-container matColumnDef="created">
      <mat-header-cell mat-header-cell *matHeaderCellDef> Last Ping </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let element"> {{element.createdDate | localBrowserTime}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="latLong">
      <mat-header-cell mat-header-cell *matHeaderCellDef> Lat, Long </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let element">
        {{element.latitude}}, {{element.longitude}}
      </mat-cell>
    </ng-container>

    <!-- Header row -->
    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>

    <!-- Primary row -->
    <mat-row mat-row *matRowDef="let element; columns: columns"></mat-row>

  </mat-table>

</ng-container>

<ng-container *ngIf="paginationState$ | async as pagination">
  <ng-container *ngIf="pagination">
    <ng-container *ngIf="pagination.length! > pagination.pageSize!">
      <mat-paginator [length]="pagination.length" (page)="onPageEvent($event)" [pageIndex]="pagination.pageIndex"
                     [pageSize]="pagination.pageSize"
                     [pageSizeOptions]="[5, 10, 24]">
      </mat-paginator>
    </ng-container>
  </ng-container>

</ng-container>
