<svg xmlns='http://www.w3.org/2000/svg' viewBox='-34 -18 250 40'>
  <path d='m -7.7234907,-7.840255 h 4.709239 v 2.1978561 a 8.0111716,8.173423 0 0 1 2.68139743,-2.022446 7.8324124,7.9910428 0 0 1 3.21767627,-0.6691145 6.0140719,6.1358756 0 0 1 3.1766873,0.8793749 q 1.4141382,0.8805364 2.2771947,2.5684247 a 7.4213782,7.5716839 0 0 1 6.314661,-3.4477996 q 2.000516,0 3.518266,0.950236 a 5.379873,5.4888323 0 0 1 2.18383,2.4813001 q 0.668357,1.5299033 0.66608,4.97770285 V 11.298053 H 16.279283 V 1.5877578 q 0,-3.252642 -0.797019,-4.4050052 -0.797018,-1.152365 -2.37853,-1.1535267 A 3.6298488,3.7033645 0 0 0 10.9404,-3.2737791 4.0818721,4.1645424 0 0 0 9.5182916,-1.3280033 Q 9.0571587,-0.08502965 9.0537435,2.6599676 V 11.29573 H 4.3126232 V 2.028026 q 0,-2.56958626 -0.3711832,-3.7173041 C 3.6943649,-2.4606191 3.3209049,-3.0321542 2.8290307,-3.4085318 a 2.9478289,3.0075315 0 0 0 -1.800122,-0.5622423 3.5296522,3.6011386 0 0 0 -2.132594,0.7132586 4.1308314,4.2144938 0 0 0 -1.4357708,1.9957269 q -0.4782107,1.2859553 -0.4747956,4.0472152 v 8.5126264 h -4.709239 z' style='fill:%230078d3;stroke-width:8.81342125' />
  <polygon points='440.05,4.61 397.7,4.61 350.45,107.98 307.69,4.61 265.42,4.61 328.05,157.08 294.17,229.64 336.87,229.64 ' style='fill:%230078d3;stroke-width:76.63378906' transform='matrix(0.11385974,0,0,0.11616576,-8.2927896,-8.375779)' />
  <path d='M 66.992417,1.7178631 H 54.659128 v 1.9260283 h 9.07804 a 7.7777593,7.9352831 0 0 1 0.157127,1.1070598 c 0,2.1664917 -0.401929,3.5209843 -1.196669,4.0297902 -0.794741,0.5088063 -2.937585,0.7678559 -6.351101,0.7678559 -4.468992,0 -7.25628,-0.2729897 -8.27874,-0.8131603 C 47.045324,8.1952658 46.540926,6.7350621 46.540926,4.3745743 l -0.01823,-2.7124705 0.04098,-2.5207966 c 0,-2.3233151 0.462271,-3.795136 1.372011,-4.3701559 0.909736,-0.575021 3.254109,-0.8747284 6.945445,-0.8747284 3.999893,0 6.50822,0.1823808 7.44984,0.5448181 0.941622,0.3624364 1.393647,1.3033795 1.393647,2.8193425 v 0.5320384 h 3.270053 v -0.7783107 c 0,-2.1223475 -0.721871,-3.5105285 -2.145122,-4.1262068 -1.423237,-0.6156793 -4.570319,-0.9153866 -9.401392,-0.9153866 -4.831067,0 -8.073794,0.4727952 -9.670108,1.393989 -1.596312,0.9211947 -2.420656,2.8402535 -2.420656,5.66075756 l -0.02279,1.68440349 0.02279,2.79843345 c 0,3.3560285 0.732117,5.5480763 2.176995,6.5180607 1.444883,0.969984 4.708105,1.448586 9.727041,1.448586 5.306999,0 8.639674,-0.413549 9.905798,-1.227871 1.266124,-0.8143224 1.915124,-2.9715205 1.915124,-6.3984103 a 18.70374,19.082549 0 0 0 -0.0854,-2.0816907 z' style='fill:%2325477b;stroke-width:8.74400139' />
  <polygon points='718.09,75.97 718.09,21.27 850.75,21.27 850.75,4.68 688.81,4.68 688.81,169.24 850.75,169.24 850.75,152.67 718.09,152.67 718.09,92.55 845.26,92.55 845.26,75.97 ' style='fill:%2325477b;stroke-width:76.03018951' transform='matrix(0.11385974,0,0,0.11616576,-8.2927896,-8.375779)' />
  <path d='m 95.330961,-5.1126827 c 0.749197,-0.6563367 3.402131,-0.9874095 7.880239,-0.9874095 3.90196,0 6.4103,0.2834448 7.45553,0.8422022 1.04524,0.5587574 1.55761,1.8784004 1.55761,3.9496351 l 0.0182,1.79476146 V 1.7190248 c 0,3.8903917 -0.37801,6.1916349 -1.12721,6.8468099 -0.7492,0.6551748 -3.41579,0.9862475 -7.90414,0.9862475 -4.48836,0 -7.131042,-0.3299109 -7.880239,-0.9862475 -0.749187,-0.6563365 -1.129482,-2.9564182 -1.129482,-6.8468099 0,-3.890391 0.379156,-6.1776951 1.12949,-6.8317075 m 18.047901,-1.3300984 c -1.3538,-1.0513001 -4.76731,-1.5845011 -10.14718,-1.5845011 -4.652314,0 -7.876816,0.464663 -9.584714,1.393989 -1.707895,0.929326 -2.590309,2.6880755 -2.590309,5.2274591 l -0.04101,1.79708457 V 1.7190248 c 0,4.3701561 0.671779,7.1221228 1.994825,8.175746 1.323055,1.0536232 4.759336,1.5868242 10.221178,1.5868242 5.49145,0 8.9323,-0.537847 10.23258,-1.5984407 1.30028,-1.0605934 1.96522,-3.8985229 1.96522,-8.4150473 0,-4.1935842 -0.69,-6.8537801 -2.04947,-7.9108881' style='fill:%2325477b;stroke-width:8.74400139' />
  <polygon points='1179.7,22.82 1179.7,169.24 1208.95,169.24 1208.95,22.82 1289.88,22.82 1289.88,4.67 1098.03,4.67 1098.03,22.82 ' style='fill:%2325477b;stroke-width:76.03018951' transform='matrix(0.11385974,0,0,0.11616576,-8.2927896,-8.375779)' />
  <path d='m 154.35243,5.5327468 h -12.38565 l 6.14842,-11.43071 z m -4.00786,-13.3660322 h -4.57944 L 135.25361,11.284114 h 3.53762 l 2.1952,-4.0228209 h 14.37025 l 2.21344,4.0228209 h 3.49663 L 150.36392,-7.8030815 Z' style='fill:%2325477b;stroke-width:8.74400139' />
  <path d='m 167.3222,2.4473846 h 8.23661 c 3.37253,0 5.46527,0.1905114 6.22244,0.5715355 0.75717,0.3810235 1.11696,1.4056052 1.11696,3.0714224 0,1.3335831 -0.37345,2.218766 -1.10786,2.6288311 -0.73438,0.410065 -2.35917,0.6261334 -4.79007,0.6261334 l -2.90456,0.013939 h -6.77352 z m 13.5698,-2.55564738 c -0.76399,0.42052077 -2.50491,0.63310345 -5.16581,0.63310345 H 167.3222 V -5.9049338 h 9.28185 c 2.27719,0 3.76876,0.1858656 4.4337,0.5552726 0.66494,0.369407 0.9826,1.1825671 0.9826,2.4394811 0,1.4462635 -0.38028,2.38952883 -1.12835,2.80191732 m 0.79702,1.37424158 c 2.33868,-0.58082892 3.52966,-1.93415964 3.52966,-4.0088811 0,-2.0747195 -0.56702,-3.4733559 -1.65779,-4.122722 -1.0908,-0.6493669 -3.40328,-0.9676611 -6.89195,-0.9676611 H 163.98952 V 11.284114 h 14.03549 c 2.94898,0 5.05196,-0.412389 6.24977,-1.233681 1.1978,-0.821292 1.81036,-2.2687171 1.81036,-4.2981329 0,-2.4685223 -1.48016,-3.9810009 -4.40181,-4.4967767' style='fill:%2325477b;stroke-width:8.74400139' />
  <path d='m 190.75453,-7.265235 h -1.67828 v 4.2261112 h -0.61941 V -7.265235 h -1.67829 v -0.5668888 h 3.9851 z' style='fill:%2325477b;stroke-width:8.74400139' />
  <path d='m 196.08545,-3.0391238 h -0.6251 v -4.1296936 l -1.31052,2.8100496 h -0.37232 l -1.29686,-2.8100496 v 4.1296936 h -0.58295 v -4.793 h 0.85166 l 1.25246,2.6718131 1.21146,-2.6718131 h 0.87217 z' style='fill:%2325477b;stroke-width:8.74400139' />
</svg>
