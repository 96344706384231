<h1 class="form-title">
  Maintenance Documents
</h1>
<div class="message-container">
  <div class="row">

    <div class="col-md-12 mt-4">
      <div class="m-r-container flex gap-20">
        <mat-icon class="m-r-left-icons orange" fontIcon="oil_barrel"></mat-icon>
        <p class="drs-p">
          <b>Proof of a Quarterly Oil Change and Chassis Lube</b>
          <br/>
          This assists us in verifying that the vehicle is well-maintained and <br/>
          that any potential issues are addressed promptly.
        </p>
      </div>
    </div>

    <div class="col-md-12 mt-4">
      <div class="m-r-container flex gap-20">
        <mat-icon class="m-r-left-icons orange" fontIcon="receipt_long"></mat-icon>
        <p class="drs-p">
          <b>Proof of a Quarterly Oil Change and Chassis Lube</b>
          <br/>
          This assists us in verifying that the vehicle is well-maintained and <br/>
          that any potential issues are addressed promptly.
        </p>
      </div>
    </div>

  </div>
</div>
<div class="form-region">
  <form [formGroup]="form" class="form" (ngSubmit)="submit()">
    <div class="form-field">
      <mat-label class="field-label">Lease
        <span class="required">(required *)</span>
      </mat-label>
      <mat-form-field class="full-width">
        <mat-select formControlName="lease">
          <mat-option *ngFor="let option of leases$ | async" [value]="option">{{ option.leaseId }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="form-field">
      <mat-label class="field-label">
        Document Type
        <span class="required">(required *)</span>
      </mat-label>
      <mat-form-field class="full-width">
        <mat-select formControlName="associatedDocumentTypeId">
          <mat-option *ngFor="let option of documentTypes" [value]="option.id">{{ option.name }}</mat-option>
        </mat-select>
        <mat-hint>Apply a selection that best describes the type of document</mat-hint>
      </mat-form-field>
    </div>
    <div class="form-field">
      <mat-label class="field-label">
        Document Date (Service Date)
        <span class="required">(required *)</span>
      </mat-label>
      <mat-form-field class="full-width">
        <input matInput [matDatepicker]="picker" [max]="maxAllowed" formControlName="associatedServiceDate">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="row">
      <div class="col col-lg-12 col-xl-12">
        <div class="drop-containerr">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="file-input-container">
                <label class="file-input__label"
                       (click)="triggerFileInput($event)"
                       for="file-input">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="upload"
                    class="svg-inline--fa fa-upload fa-w-16"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="currentColor"
                      d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                    ></path>
                  </svg>
                  <span>Upload file</span>
                </label>
                <input accept=".pdf,.png,.jpeg,.jpg"
                       id="file-input"
                       name="file-input"
                       type="file" #fileInput id="fileInput" class="file-input hidden"
                       (change)="onFileSelected($event)">
              </div>
              <div *ngFor="let file of documents; let i = index">
                <div class="file-link">
                  <span>{{ file.file?.name }}</span>
                  <span class="flex-expand"></span>
                  <mat-icon class="icon" (click)="removeUploadedFile($event, file.file?.name)">cancel</mat-icon>
                </div>
              </div>
              <div class="btn-submit">

                <loading-button mat-stroked-button color="primary" [busy]="(uploading$ | async)"
                                [disabled]="!valid" class="w-100"
                                type="submit">
                  <loading-button-ready>
                    Submit Document
                  </loading-button-ready>
                  <loading-button-busy>
                    Submitting Document...
                  </loading-button-busy>
                </loading-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </form>

</div>
