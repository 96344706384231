import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VisibilityService {
  private currentPaymentSubject = new BehaviorSubject<boolean>(false);
  private pastDueSubject = new BehaviorSubject<boolean>(false);

  currentPayment$ = this.currentPaymentSubject.asObservable();
  pastDue$ = this.pastDueSubject.asObservable();

  setCurrentPaymentVisibility(isVisible: boolean): void {
    this.currentPaymentSubject.next(isVisible);
  }

  setPastDueVisibility(isVisible: boolean): void {
    this.pastDueSubject.next(isVisible);
  }
 
}
