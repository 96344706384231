import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import {MatXtnSnackbarSuccessModule} from "../snackbar-success";
import {AllSharedComponents} from "./components";
import {AllSharedPipes} from "./pipes";

@NgModule({
  declarations: [
    ...AllSharedComponents(),
    ...AllSharedPipes()

  ],
  imports: [
    CommonModule,
    MaterialModule,
    MatXtnSnackbarSuccessModule
  ],
  exports: [
    ...AllSharedComponents(),
    ...AllSharedPipes()
  ]
})
export class SharedModule { }
