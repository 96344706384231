import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from "@material/material.module";
import {SharedModule} from "@shared/shared.module";
import {NgxsModule} from "@ngxs/store";
import {GeoTabLeaseViewerComponent} from "./components/lease-viewer/geo-tab-lease-viewer.component";
import {GeotabLogoComponent} from "./components/logo/geotab-logo.component";
import {GeotabTableComponent} from "./components/table/geotab-table.component";
import {GeoTabMapPingComponent} from "./components/map-ping/geo-tab-map-ping.component";
import {GeotabTelematicDialogComponent} from "./components/telematic";

@NgModule({
  declarations: [
    GeoTabLeaseViewerComponent,
    GeotabLogoComponent,
    GeotabTableComponent,
    GeoTabMapPingComponent,
    GeotabTelematicDialogComponent
  ],
  providers: [],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    NgxsModule
  ],
  exports: [
    GeoTabLeaseViewerComponent,
    GeotabLogoComponent,
    GeotabTableComponent,
    GeoTabMapPingComponent
  ]
})
export class GeoTabModule {
}
