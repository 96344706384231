<ng-container *ngIf="((customerLoading$ | async) || (loading$ | async)); else done">
</ng-container>
<ng-template #done>
  <div class="wrapper">
    <app-sidebar></app-sidebar>
    <div class="main">
      <app-navbar></app-navbar>
      <main class="content">
        <div class="container-fluid p-0">
          <div class="row">
            <div class="col-xl-12 col-xxl-12 d-flex">
              <div class="form-container">

                <router-outlet />

              </div>
            </div>
          </div>
        </div>
      </main>
      <app-footer></app-footer>
    </div>
  </div>
</ng-template>

