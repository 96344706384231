import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpServiceService} from "../../services/http-service.service";
import {environment} from "../../../environments/environment";
import {mergeMap, tap} from "rxjs";
import {MatXtndSnackbarSuccessService} from "../../modules/snackbar-success";
import { IInsuranceStateModel, InsuranceType } from './insurance.model';
import { InsuranceActions } from './insurance.actions';

@State<IInsuranceStateModel>({
  name: 'insurance',
  defaults: <IInsuranceStateModel>{
    loading: false,
    busy: false,
    current: null,
  }
})
@Injectable()
export class InsuranceState {

  constructor(
    private readonly http: HttpServiceService,
    private readonly snackbarSuccessService: MatXtndSnackbarSuccessService
  ) {
  }

  get baseApi() {
    return `${environment.lease_path.base_uri}` ;
  }

  @Selector()
  static IsLoading(state: IInsuranceStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static IsWorking(state: IInsuranceStateModel): boolean {
    return state.busy;
  }

  @Selector()
  static getCurrent(state: IInsuranceStateModel): InsuranceType | null {
    return state.current;
  }

  @Action(InsuranceActions.Done)
  onDone(ctx: StateContext<IInsuranceStateModel>) {
    ctx.patchState({
      loading: false,
      busy: false
    });
  }

  @Action(InsuranceActions.Loading)
  onLoading(ctx: StateContext<IInsuranceStateModel>) {
    ctx.patchState({
      loading: true
    });
  }

  @Action(InsuranceActions.Working)
  onWorking(ctx: StateContext<IInsuranceStateModel>) {
    ctx.patchState({
      busy: true
    });
  }

  @Action(InsuranceActions.UploadDocument)
  onUploadDocument(ctx: StateContext<IInsuranceStateModel>, action: InsuranceActions.UploadDocument) {
    return ctx.dispatch(new InsuranceActions.Working()).pipe(
      mergeMap(() => this.http.postDataWithToken(`${this.baseApi}Emails/certificate-of-insurance`, action.request)),
      tap((response) => {
        ctx.dispatch(new InsuranceActions.Done());
        return response; 
      })
    );
  }



}
