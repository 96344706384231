import {PageEvent} from "@angular/material/paginator";

export namespace GeotabActions {
  export class Loading {
    static readonly type = '[Geotab] Set As Loading';
  }

  export class Working {
    static readonly type = '[Geotab] Set As Working';
  }

  export class Done {
    static readonly type = '[Geotab] Set As Done';
  }

  export class LoadGeoTabLatest {
    static readonly type = '[Geotab] Load Latest';
  }

  export class LoadGeoTabByLeases {
    static readonly type = '[Geotab] Load By Lease';

    constructor(public readonly leaseId: Array<string>) {
    }
  }

  export class SetPingPaginationState {
    static readonly type = '[Geotab] Load Ping Page Pagination State';

    constructor(public readonly request: Partial<PageEvent>) {
    }
  }

  export class LoadPingsPage {
    static readonly type = '[Geotab] Load Ping Page';
  }

  export class LoadLeaseLastDayCalculation {
    static readonly type = '[Geotab] Load Lease Last Day Calculation';

    constructor(public readonly leaseId: string) {
    }
  }
  export class LoadTelematic{
    static readonly type = '[Geotab] Load Telematic';
    constructor(public readonly vin: string) { }
  }


}


