import { Component, ElementRef, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FileDTO } from '../../../../states/upload-documents/upload-documents.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LeaseResponseType } from '../../../../states/lease/lease.model';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { LeaseState } from '../../../../states/lease/lease.state';
import { FormBuilder, Validators } from '@angular/forms';
import { GeotabState } from '../../../../states/geotab/geotab.state';
import { GeotabType } from '../../../../states/geotab/geotab.model';
import { CommonService } from '../../../../services/common.service';
import { InsuranceActions } from '../../../../states/insurance';

@Component({
  selector: 'app-submit-coi',
  templateUrl: './submit-coi.component.html',
  styleUrls: ['./submit-coi.component.css']
})
export class SubmitCoiComponent implements OnInit, OnDestroy {

  @ViewChild('fileInput') fileInput: ElementRef;
  selectedFiles: Array<File> = [];
  documents: Array<FileDTO> = [];
  isEmptyAttachDocument: boolean = true;

  @Select(LeaseState.GetLeases) leases$: Observable<LeaseResponseType[]>;
  form = this.formBuilder.group({
    lease: [null, [Validators.required]]
  });

  latestGeoTabLeases: Array<GeotabType> = [];

  constructor(
    public dialogRef: MatDialogRef<SubmitCoiComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly formBuilder: FormBuilder,
    private store: Store,
    private readonly commonService: CommonService,
  ) {
    //this.data.fields;
    //if (this.leaseId) 
    //  this.store.dispatch(new GeotabActions.LoadGeoTabByLeases([this.leaseId]));
    this.store.select(GeotabState.getLatestGeoTabLeases).subscribe((geoTabData) => {
      this.latestGeoTabLeases = geoTabData || [];
    });    
  }

  ngAfterViewInit(): void {
    
  }
  ngOnInit() {

  }

  ngOnDestroy() {

  }

  get lease(): LeaseResponseType | null {
    return this.form.get('lease')?.value || null;
  }

  getErrorMessage(dataObject: any) {
    if (dataObject != null) {
      if (this.form.get(dataObject)?.hasError('required')) {
        return `You must enter a value`;
      }
    }
    return '';
  }

  onClose(): void {
    this.dialogRef.close();
  }

  triggerFileInput(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.fileInput.nativeElement.value = null;
    this.fileInput.nativeElement.click();
  }

  onFileSelected(event: any) {
    this.selectedFiles = event.target.files;
    if (this.selectedFiles[0]) {
      this.documents = [{ file: this.selectedFiles[0] }];
      this.isEmptyAttachDocument = false;
      //if (this.dialog.openDialogs.length === 0)
       // this.openDialog();
    }
  }

  removeUploadedFile(event: any, fileName: any, type: string = '') {
    this.documents = this.documents.filter(file => file.file?.name !== fileName);
    const target = event.target as HTMLElement;
    const parent = target.closest('.file-link');
    if (parent)
      parent.remove();
    if (this.documents.length == 0)
      this.isEmptyAttachDocument = true;
  }

  submit() {
    if (this.form.valid || this.data?.leaseId) {
      const leaseId = this.data?.leaseId ? this.data?.leaseId : this.lease?.leaseId;
      const unitNumber = this.latestGeoTabLeases.find(x => x.leaseId === leaseId)?.unitNumber || 0;
      
      this.commonService.startSpinner();
      const formData = new FormData();
      formData.append('LeaseID', leaseId);
      formData.append('UnitNumber', unitNumber.toString());
      formData.append('File', this.selectedFiles[0]);
      this.store.dispatch(new InsuranceActions.UploadDocument(formData)).subscribe(
        (response) => {
          if (response?.insurance) {
            this.commonService.openSnackBar("COI successfully submitted to Berkshire.", '', 'success');
          }
          this.commonService.stopSpinner()
        },
        (error) => {
          this.commonService.openSnackBar("Document upload failed: " + error?.message, '', 'error');
          this.commonService.stopSpinner();
        }
      );

      this.dialogRef.close();

      //this.form.reset();
     // this.documents = [];
      //this.selectedFiles = [];
     // this.fileInput.nativeElement.value = null;
    }    
  }

}
