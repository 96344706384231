<div class="header">
    <h3>{{data.title}}</h3>
</div>
<div class="content">

  <mat-table class="table" [dataSource]="dataSource">

    <ng-container matColumnDef="created">
      <mat-header-cell *matHeaderCellDef> Incident Time </mat-header-cell>
      <mat-cell *matCellDef="let row" data-label="Incident Time">
        <span class="mobile-label"> Incident Time</span>
        <span class="value">{{row.dateTime | localBrowserTime}}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="code">
      <mat-header-cell *matHeaderCellDef> Code </mat-header-cell>
      <mat-cell *matCellDef="let row" data-label="Code">
        <span class="mobile-label"> Code </span>
        <span class="value">
          <span class="muted minimal-muted">{{row.rule}}</span>
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row" data-label="action">
        <span class="mobile-label"> </span>
        <span class="value">
          <mat-icon color="primary" matTooltipPosition="above"
                    matTooltip="{{row.diagnostic}}">info</mat-icon>
        </span>
      </mat-cell>
    </ng-container>

    <!-- Header row -->
    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>

    <!-- Primary row -->
    <mat-row mat-row *matRowDef="let element; columns: columns"></mat-row>

  </mat-table>

  <mat-paginator [pageSizeOptions]="[8, 20]" ></mat-paginator>

</div>
