import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

const materialButtonAttrs = [
  'mat-button',
  'mat-raised-button',
  'mat-icon-button',
  'mat-fab',
  'mat-mini-fab',
  'mat-stroked-button'
];
const HOST_SELECTOR_MDC_CLASS_PAIR: {selector: string; mdcClasses: string[]}[] = [
  {
    selector: 'mat-button',
    mdcClasses: ['mdc-button', 'mat-mdc-button'],
  },
  {
    selector: 'mat-flat-button',
    mdcClasses: ['mdc-button', 'mdc-button--unelevated', 'mat-mdc-unelevated-button'],
  },
  {
    selector: 'mat-raised-button',
    mdcClasses: ['mdc-button', 'mdc-button--raised', 'mat-mdc-raised-button'],
  },
  {
    selector: 'mat-stroked-button',
    mdcClasses: ['mdc-button', 'mdc-button--outlined', 'mat-mdc-outlined-button'],
  },
  {
    selector: 'mat-fab',
    mdcClasses: ['mdc-fab', 'mat-mdc-fab'],
  },
  {
    selector: 'mat-mini-fab',
    mdcClasses: ['mdc-fab', 'mdc-fab--mini', 'mat-mdc-mini-fab'],
  },
  {
    selector: 'mat-icon-button',
    mdcClasses: ['mdc-icon-button', 'mat-mdc-icon-button'],
  },
];


@Component({
  selector: 'loading-button',
  templateUrl: 'loading-button.component.html',
  styleUrls: [`loading-button.component.scss`]
})
export class LoadingButtonComponent {

  private _busy = false;

  @Input() set busy(value: BooleanInput) {
    this._busy = coerceBooleanProperty(value);
  }
  get busy() { return this._busy; }
  @Input() disabled: boolean = false;
  @Input() color: ThemePalette = 'primary';

  class: string = '';
  buttonType: string = 'button';

  @Output()
  private submit: EventEmitter<any> = new EventEmitter<any>();

  constructor(element: ElementRef) {
    const nativeEl = element.nativeElement as HTMLElement;

    materialButtonAttrs.forEach((attr, index) => {
      if (nativeEl.hasAttribute(attr)) {
        this.class = `${attr} ${this.class}`

        const mdcClass = HOST_SELECTOR_MDC_CLASS_PAIR.find(pair => pair.selector === attr);
        if (mdcClass) {
          this.class = `${this.class} ${mdcClass.mdcClasses.join(' ')}`;
        }
      }
    })

    if (nativeEl.hasAttribute('type')) {
      this.buttonType = nativeEl.getAttribute('type') as string;
    }
  }

  onClick($ev: any) {
    this.submit.emit($ev);
  }


}
