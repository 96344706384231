import {AfterViewInit, Component, Renderer2} from '@angular/core';
import {Select} from "@ngxs/store";
import {Observable} from "rxjs";
import {CustomerState} from "@states/customer/customer.state";
import {OtrTokenState} from "@states/otr-token/otr-token.state";

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements AfterViewInit {
  @Select(OtrTokenState.IsLoading) loading$: Observable<boolean>;
  @Select(CustomerState.IsLoading) customerLoading$: Observable<boolean>;
  constructor(private renderer: Renderer2) { }

  ngAfterViewInit() {
    this.loadExternalScript('assets/js/sidebar-toggle.js').then(() => {
      // Ensure window.initialize is available before calling it
      if (typeof (window as any).initialize === 'function') {
        (window as any).initialize();
      } else {
        console.error('initialize function is not defined on the window object');
      }
    }).catch(error => {
      console.error('Error loading external script', error);
    });
  }

  private loadExternalScript(scriptUrl: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const scriptElement = this.renderer.createElement('script');
      scriptElement.src = scriptUrl;
      scriptElement.onload = () => {
        resolve();
      };
      scriptElement.onerror = (error: any) => {
        reject(error);
      };
      this.renderer.appendChild(document.body, scriptElement);
    });
  }

}
