import {auth0User} from "@states/auth/auth.model";

export namespace AuthActions{
  export class Loading {
    static readonly type = '[auth] Set As Loading';
  }

  export class Working {
    static readonly type = '[auth] Set As Working';
  }

  export class Done {
    static readonly type = '[auth] Set As Done';
  }
  export class ApplyUserSession {
    static readonly type = '[auth] Apply User Session';
  }
  export class InitializeUserSession {
    static readonly type = '[auth] Initialize User Session';
    constructor(public readonly user: auth0User) {
    }
  }

  export class UserSessionChange {
    static readonly type = '[auth] User Session Change';

    //constructor(public user: auth0User) {

    //}
  }

  export class Login{
    static readonly type = '[auth] Login';
  }
  export class LogOut{
    static readonly type = '[auth] LogOut';
  }

}
