
export namespace FinancialActions {
  export class Loading {
    static readonly type = '[Financial] Set As Loading';
  }
  export class Working {
    static readonly type = '[Financial] Set As Working';
  }
  export class Done {
    static readonly type = '[Financial] Set As Done';
  }
  export class GetEscrowData {
    static readonly type = '[Financial] Get Escrow Transaction';
  }
  export class GetPaymentActivityByCustomerData {
    static readonly type = '[Financial] Get Payment Activity';
  }

}


