
export class LeasesInnStateModel {
  loading: boolean
  leases: LeaseStateModel[]
}

export class LeaseStateModel {
  customerId: string
  leaseId: string
  baseUnitId: number
  leaseBeginDate: string
  leaseEndDate: Date
  firstName: string
  businessName: string
  address1: string
  city: string
  state: string
  postalCode: string
  emailAddress: string
  phoneNumber: string
  assetModelYear: string
  assetMake: string
  assetModel: string
  vin: string
  weeksDlq: number
  id: number
  lease: any
}
export type LeaseResponseType = {
  customerId: string
  leaseId: string
  baseUnitId: number
  leaseBeginDate: string
  leaseEndDate: Date
  firstName: string
  businessName: string
  address1: string
  city: string
  state: string
  postalCode: string
  emailAddress: string
  phoneNumber: string
  assetModelYear: string
  assetMake: string
  assetModel: string
  vin: string
  weeksDlq: number
  id: number
  lease: any
}




