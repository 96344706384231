<h1 class="form-title">
  Lease Information
</h1>

<div class="form-content">

  <ng-container *ngIf="(lease$ | async) as lease">
    <form class="example-form">
      <div class="row mt-2">
        <div class="col-lg-12">
          <p class="sub-title-txt orange">
            Lease {{ leaseId }}
          </p>
        </div>

        <ng-container *ngIf="(payment$ | async) as payment">
          <div class="col-lg-6 mb-4">
            <div>
              <mat-icon class="orange title-faq-icon" fontIcon="local_shipping"></mat-icon>
              <span class="faq-links-text">Truck : {{lease.assetModelYear}} {{lease.assetMake}} {{lease.assetModel}}</span>
            </div>
          </div>
          <div class="col-lg-6 mb-4">
            <div>
              <mat-icon class="orange title-faq-icon" fontIcon="event"></mat-icon>
              <span *ngIf="(payment.leaseAutoDebitInd == 'true' ? true : false)" class="faq-links-text">Next Payment Scheduler for ACH : {{ formatDate(payment.nextDueDate) }}</span>
              <span *ngIf="!(payment.leaseAutoDebitInd == 'true' ? true : false)" class="faq-links-text">Payment Due Date : {{ formatDate(payment.nextDueDate) }}</span>
            </div>
          </div>
          <div class="col-lg-6 mb-4">
            <div>
              <mat-icon class="orange title-faq-icon" fontIcon="tag"></mat-icon>
              <span class="faq-links-text">Last 6 of VIN : {{lease.vin.slice(-6)}}</span>
            </div>
          </div>
          <div class="col-lg-6 mb-4">
            <div>
              <mat-icon class="orange title-faq-icon" fontIcon="calendar_month"></mat-icon>
              <span class="faq-links-text" *ngIf="isLeaseEndDateValid(payment.nextDueDate); else noDueDate">
                Next PM Due Date :
                <ng-container *ngIf="(maintenance$ | async) as maintenance">
                  {{ formatDate(maintenance.dueDate) }}
                </ng-container>
                <span class="d-l-link" (click)="routeUploadLink(lease.leaseId)"> Please Upload PM Documentation</span>
              </span>

              <ng-template #noDueDate>
                <span>You haven’t provided your PM information as required, <span class="d-l-link" (click)="routeUploadLink(lease.leaseId)">Please upload</span> or contact <a class="d-l-link">telematics@otrleasing.com</a></span>
              </ng-template>

            </div>
          </div>

          <div class="col-lg-6 mb-4">
            <div>
              <mat-icon class="orange title-faq-icon" fontIcon="payments"></mat-icon>
              <span class="faq-links-text d-l-link" (click)="routerLeaseLink()">Make a one time Payment</span>
            </div>
          </div>

          <div class="col-lg-6 mb-4">
            <div>
              <mat-icon class="orange title-faq-icon" fontIcon="calendar_view_day"></mat-icon>
              <span class="faq-links-text">Weeks Delinquent : {{lease.weeksDlq || 0}}</span>
            </div>
          </div>


        </ng-container>


        <ng-container *ngIf="escrow$ | async as escrow">
          <div class="col-lg-12 mt-3"><h6 class="custom-tbl-title">Escrow Activity</h6></div>
            <div class="col-lg-12 mb-4">
              <table class="table table-striped table-bordered table-responsive custom-tbl" style="max-width: 600px;">
                <thead>
                  <tr>
                    <th>Asset ID</th>
                    <th>Lease ID</th>
                    <th>PM Fin Type</th>
                    <th width="150">Escrow Amount ($)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ escrow.assetId }}</td>
                    <td>{{ escrow.leaseId }}</td>
                    <td>{{ escrow.pmfinType }}</td>
                    <td>{{ escrow.escrowAmt || 0 }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
        </ng-container>

        <div class="container">
          <ng-container *ngIf="paymentActivityByCustomer$ | async as paymentActivities; else noPAData">
            <div class="col-lg-12"><h6 class="custom-tbl-title">Payment Activity</h6></div>
            <div class="col-lg-12">
              <table class="table table-striped table-bordered table-responsive custom-tbl">
                <thead>
                  <tr>
                    <th>Lease ID</th>
                    <th>GL Code</th>
                    <th>Effective Date</th>
                    <th>Post Date</th>
                    <th>Lease Fin Type</th>
                    <th>Principal ($)</th>
                    <th>Total ($)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let activity of paymentActivities">
                    <td>{{ activity.leaseId }}</td>
                    <td>{{ activity.glcode }}</td>
                    <td>{{ formatDate(activity.effectiveDate) }}</td>
                    <td>{{ formatDate(activity.postDate) }}</td>
                    <td>{{ activity.leaseFinType }}</td>
                    <td>{{ activity.leaseFinPrincipal || 0 }}</td>
                    <td>{{ activity.leaseFinTotal || 0 }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

          </ng-container>

          <ng-template #noPAData>
            <p class="text-center">No payment activity data available.</p>
          </ng-template>
        </div>



      </div>
    </form>
  </ng-container>
</div>

