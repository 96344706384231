import { Action, NgxsOnInit, Select, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { HttpServiceService } from '../../services/http-service.service';
import { DashboardStateModel } from './dashboard.model';
import { DashboardAction } from './dashboard.actions';
import { environment } from '../../../environments/environment';
import { catchError, EMPTY, Observable, tap, throwError } from 'rxjs';
import { OtrTokenActions } from '../otr-token/otr-token.actions';
import { CustomerState } from '../customer/customer.state';

@State<DashboardStateModel>({
  name: 'dashboard',
  defaults: <DashboardStateModel>{
  }
})

@Injectable()

export class DashboardState {
  baseUrl: string;
  @Select(CustomerState.GetCustomerId) customer$: Observable<string>
  constructor(
    private http: HttpServiceService,
    private store: Store) {

    this.baseUrl = environment.lease_path.base_uri;
  }

  @Selector()
  static GetData(state: DashboardStateModel): any {
    return state;
  }

   @Action(DashboardAction.getData)
   onGetData(ctx: StateContext<DashboardStateModel>) {
     const customerId = this.store.selectSnapshot(CustomerState.GetCustomerId);
     if(customerId){
       const url = `${this.baseUrl}Customers/${customerId}/dashboard`;
       return this.http.getDataWithToken(url).pipe(
         tap((responseArray: DashboardStateModel[]) => {
           const data = responseArray[0];         
           if (data == undefined)
             return;

           ctx.patchState(data);
         }),
         catchError((error) => {
           console.error('Error while fetching dashboard API:', error.message);
           return throwError(error);
         })
       ).subscribe();
     }
     return EMPTY;
   }
}
