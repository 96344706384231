import { Component, Host } from '@angular/core';
import { LoadingButtonComponent } from '../loading-button.component';

@Component({
    selector: 'loading-button-busy',
    templateUrl: 'loading-button-busy.component.html',
    styleUrls: [`loading-button-busy.component.scss`]
  })
  export class LoadingButtonBusyComponent {
   
  constructor(@Host() public parentContext: LoadingButtonComponent) {}
  
 } 
