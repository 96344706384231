
export interface IMaintenanceStateModel {
    loading: boolean;
    busy: boolean;
    current: MaintenanceType | null;
  }

  export type MaintenanceType =  {

  }
