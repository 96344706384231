import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localBrowserTime'
})
export class LocalBrowserTimePipe implements PipeTransform {

  public transform(value: any, format: string = 'medium'): string | null {
    let date = new Date(value);
    const adjusted = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
    const offset = date.getTimezoneOffset() / 60;
    const hours = date.getHours();
    adjusted.setHours(hours - offset);
    return new DatePipe('en-US').transform(adjusted, format);
  }
}
