<h1 class="form-title">
  Dashboard
</h1>
<div class="d-t-right-text">
  <div>Login user as of</div>
  <div><span class="login-datetime-text">{{ (user$ | async)?.updated_at | date: 'medium'}}</span></div>
</div>
<div class="row ms-2 me-2">
  <div class="col-lg-12 mb-5">
    <ng-container *ngIf="leases$ | async as leases">
      <p class="d-p-tag">
        <b>Welcome,</b> <span class="orange"> {{ firstName }},</span>
      </p>
      <p class="d-p-tag">
        Here is a list of all open leases with OTR Leasing, LLC
      </p>
    </ng-container>
  </div>

  <div class="col-lg-12 d-accordian-container mb-5">
    <ng-container *ngIf="(leases$ | async) as list">
      <mat-accordion>
        <mat-expansion-panel *ngFor="let lease of list index as i" (opened)="LoadData(lease.leaseId, lease.baseUnitId)" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Lease {{lease.leaseId}}
            </mat-panel-title>
            <!--<mat-panel-description></mat-panel-description>-->
          </mat-expansion-panel-header>

          <div class="row">
            <div class="col-lg-6">
              <p><b>Truck :</b> {{lease.assetModelYear}} {{lease.assetMake}} {{lease.assetModel}}</p>
            </div>
            <div class="col-lg-6" *ngIf="isACH">
              <p><b>Next Payment Scheduled for ACH :</b> {{formatDate(nextDueDate)}}</p>
            </div>
            <div class="col-lg-6" *ngIf="!isACH">
              <p><b>Next Payment Due Date :</b> {{formatDate(nextDueDate)}}</p>
            </div>
            <div class="col-lg-6">
              <p><b>Last 6 of VIN :</b> {{lease.vin.slice(-6)}}</p>
            </div>
            <div class="col-lg-6" *ngIf="isLeaseEndDateValid(nextDueDate); else noDueDate">
              <p><b>Next PM Due Date :</b> {{formatDate(maintenanceDueDate)}} <span class="d-l-link" (click)="routeUploadLink(lease.leaseId)">Please Upload PM Documentation</span></p>
            </div>
            <ng-template #noDueDate>
              <div class="col-lg-6">
                <p>You haven’t provided your PM information as required, <span class="d-l-link" (click)="routeUploadLink(lease.leaseId)">Please upload</span> or contact <a class="d-l-link">telematics@otrleasing.com</a></p>
              </div>
            </ng-template>

            <div class="col-lg-6">
              <p class="d-l-link" (click)="routerLeaseLink(lease.leaseId)">Make a one time Payment</p>
            </div>
          </div>

        </mat-expansion-panel>
      </mat-accordion>

    </ng-container>
  </div>



</div>
