export namespace OtrTokenActions{
  export class Loading {
    static readonly type = '[OtrToken] Loading';
  }
  export class Done{
    static readonly type = '[OtrToken] Done';
  }
  export class OtrLeasingToken {
    static readonly type = '[Auth] OtrLeasingToken';
  }
}
