import {
  QuarterlyMaintenanceRequirementsComponent
} from "./quarterly-maintenance-requirements/quarterly-maintenance-requirements.component";
import {MaintenanceUploadComponent} from "./maintenance-upload/maintenance-upload.component";

export * from './maintenance-upload/maintenance-upload.component';
export * from './quarterly-maintenance-requirements/quarterly-maintenance-requirements.component';

export function AllMaintenanceComponents() {
  return [
    QuarterlyMaintenanceRequirementsComponent,
    MaintenanceUploadComponent
  ];
}
