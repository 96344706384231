export namespace LeaseAction {
  export class Loading {
    static readonly type = '[lease] Loading';
  }
  export class Done{
    static readonly type = '[lease] Done';
  }
   export class GetData {
     static readonly type = '[lease] Get Data';
     constructor(public leaseId: string) { }
  }
  export class GetLeasesData {
    static readonly type = '[leases] Get Data';
  }
}

