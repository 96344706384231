import {Injectable} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {Observable} from "rxjs";
import {filter} from 'rxjs/operators';
import {
  GeotabTelematicDialogComponent,
  GeotabTelematicDialogParams,
  GeotabTelematicDialogResponse
} from './geotab-telematic-dialog.component';

@Injectable(
  {providedIn: 'root'}
)
export class GeotabTelematicDialogService {

  constructor(private readonly dialog: MatDialog) {
  }

  OnOpen(params: GeotabTelematicDialogParams): Observable<GeotabTelematicDialogResponse> {
    const dialogInstance = this.dialog.open(GeotabTelematicDialogComponent, {
      width: '600px',
      data: params,
      disableClose: false
    });
    return dialogInstance.afterClosed().pipe(filter(x => !!x));
  }
}
