import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { Actions, Select, Store } from '@ngxs/store';
import { CommonService } from '../../../../../services/common.service';
import { FormBuilder } from '@angular/forms';
import { CustomerState } from '../../../../../states/customer/customer.state';
import { MatDialog } from '@angular/material/dialog';
import { AccountStateModel } from '../../../../../states/account/account.model';
import { Observable, Subscription } from 'rxjs';
import { AccountAction } from '../../../../../states/account/account.actions';
import { SubmitCoiComponent } from '../../../components/submit-coi/submit-coi.component';

@Component({
  selector: 'app-insurance-requirements',
  templateUrl: './insurance-requirements.component.html',
  styleUrls: ['./insurance-requirements.component.css']
})
export class InsuranceRequirementsComponent {

  @ViewChild('submitCoiDocumentTemplate') customTemplate: TemplateRef<any>;

  customerInfo: AccountStateModel;
  //account$: Observable<AccountStateModel>;
  //accountSubscription: Subscription;

  constructor(
    private readonly store: Store,
    private readonly commonService: CommonService,
    private readonly formBuilder: FormBuilder,
    private readonly actions$: Actions,
    private dialog: MatDialog
  ) {
   // this.store.dispatch(new AccountAction.GetData());
    //this.account$ = this.store.select(state => state.account.account);
  }

  ngOnInit(): void {
    //this.commonService.startSpinner();
    //this.loadAccountData();
  }

  ngOnDestroy() {
    //if (this.accountSubscription)
      //this.accountSubscription.unsubscribe();
  }

  //loadAccountData() {

  //  this.accountSubscription = this.account$?.subscribe(
  //    data => {

  //      if (data) {
  //        this.customerInfo = data as AccountStateModel;
  //      }
  //      this.commonService.stopSpinner();
  //      if (this.accountSubscription)
  //        this.accountSubscription.unsubscribe();
  //    },
  //    error => {
  //      this.commonService.stopSpinner();
  //      console.error('Error:', error);
  //    }
  //  )
  //}

 
  openDialog(): void {
    const dialogRef = this.dialog.open(SubmitCoiComponent, {
      width: '500px',
      minHeight: '200px',
      data: null,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }      
    });
  }

}
