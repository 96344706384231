
export class PaymentStateModel {
  transactiontype: string;
  amount: number;
  creditcard: CreditCard;
  csc: string;
  billingaddress: BillingAddress;
  invoiceid: string;
  discretionaryData: DiscretionaryData;
  lastName: string;
  feeAmount: number;
}

export class CreditCard {
  number: string;
  expirationmonth: string;
  expirationyear: string;
}

export class BillingAddress {
  name: string;
  streetaddress: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

export class DiscretionaryData {
  additionalProp1: string;  
  additionalProp2: string;  
  additionalProp3: string;  
}

export class PaymentResponseModel {
  success: boolean;
  responsecode: number;
  statusMessage: string;
  transactionId: string;
  approvalcode: string;
  approvalMessage: string;
  cscResponse: string;
  avsResponse: string;
  externaltransactionId: string;
  maskedCardNumber: string;
  errors: string[];
  total: number;
  invoice: string;
  customerName: string;
}


export class CustomerPaymentStateModel {
  customerId: string
  leaseId: string
  paymentDue: string
  pastDueAmount: string
  nextDueDate: Date
  leaseAutoDebitInd: string
  satisfiedDate: string
  id: number
}

