import { Component, Host } from '@angular/core';
import { LoadingButtonComponent } from '../loading-button.component';

@Component({
  selector: 'loading-button-ready',
  templateUrl: 'loading-button-ready.component.html',
  styleUrls: [`loading-button-ready.component.scss`]
})
export class LoadingButtonReadyComponent {

  constructor(@Host() public parentContext: LoadingButtonComponent) {}

} 
