<ng-container *ngIf="geoTabDetail">
  <div class="detail-card">
    <div class="detail-heading">

      <div class="logo">
        <geotab-logo [leaseId]="geoTabDetail.leaseId" [iconAsLink]="iconAsLink" [class.logo-link]="iconAsLink"></geotab-logo>
        <div class="detail-subheading">
          <span class="muted">Most Recent Ping Detail</span>
        </div>
      </div>

      <div class="flex-fill-space"></div>

      <ng-container *ngIf="telematics$ | async as telematics">
        <ng-container *ngIf="telematics.length">
          <div>
            <button class="telematic-action"
                    matTooltip="Telematics"
                    matTooltipPosition="above"
                    (click)="displayTelematics(telematics)"
                    mat-mini-fab color="primary" aria-label="telematics">
              <mat-icon>analytics</mat-icon>
            </button>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <div class="geotab-viewer-content-detail">
      <div class="detail-item">
        <div class="detail-item-label">Odometer</div>
        <div class="detail-item-value">{{geoTabDetail.uomOdometer | number}} mi</div>
      </div>
      <ng-container *ngIf="calculation">
        <div class="detail-item">
          <div class="detail-item-label">Last
            <div class="text-muted">
              - 7 Days Mileage
            </div>
          </div>
          <div class="detail-item-value">{{calculation | number}} mi</div>
        </div>
      </ng-container>
      <div class="detail-item">
        <div class="detail-item-label">Lease</div>
        <div class="detail-item-value">{{geoTabDetail.leaseId}}</div>
      </div>
      <div class="detail-item">
        <div class="detail-item-label">Serial</div>
        <div class="detail-item-value">{{geoTabDetail.serialNumber}}</div>
      </div>
      <div class="detail-item">
        <div class="detail-item-label">Last</div>
        <div class="detail-item-value">{{geoTabDetail.createdDate | localBrowserTime}}</div>
      </div>

      <div class="detail-item">
        <div class="detail-item-label">Location</div>
        <div class="detail-item-value relative-link">
          <span class="muted">{{geoTabDetail.latitude}}, {{geoTabDetail.longitude}}</span>
          <a mat-icon-button class="detail-link" color="primary" href="https://maps.google.com/?q={{geoTabDetail.latitude}},{{geoTabDetail.longitude}}" target="_blank">
            <mat-icon>location_on</mat-icon>
          </a>
        </div>
      </div>
    </div>
  </div>
</ng-container>
