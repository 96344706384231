import { NgModule } from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { LayoutComponent } from './layout.component';

import { RouterModule, Routes } from '@angular/router';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { MatIconModule } from '@angular/material/icon';
import { MainModule } from '../views/main/main.module';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NavbarComponent } from './navbar/navbar.component';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import {MessageComponent} from "../views/public/message/message.component";

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  {
    path: '', component: LayoutComponent,
    children: [
      { path: '', loadChildren: () => MainModule }
    ]
  },
  { path: 'error-message', component: MessageComponent},
]

@NgModule({
  declarations: [
    SidebarComponent,
    NavbarComponent,
    FooterComponent,
    LayoutComponent
  ],
    imports: [
        CommonModule,
        NgxUiLoaderModule,
        MatIconModule,
        MatButtonModule,
        MatExpansionModule,
        MatListModule,
        RouterModule.forChild(routes),
        NgOptimizedImage,
    ]
})
export class LayoutModule { }
