import { Component } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import {GeotabActions, GeotabState, GeotabType} from "@states/geotab";

@Component({
  selector: 'geotab-table',
  templateUrl: 'geotab-table.component.html',
  styleUrls: [`geotab-table.component.scss`]
})
export class GeotabTableComponent  {
  @Select(GeotabState.getPingsPage) items$: Observable<Array<GeotabType>> | undefined;
  @Select(GeotabState.getPaginationPingState) paginationState$: Observable<Partial<PageEvent>> | undefined;
  columns = ['created', 'latLong'];

  constructor(private readonly store: Store) { }

  onPageEvent($event: PageEvent) {
    this.store.dispatch(new GeotabActions.SetPingPaginationState($event));
  }

}
