import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {IMaintenanceStateModel, MaintenanceType} from './maintenance.model';
import {MaintenanceActions} from './maintenance.actions';
import {HttpServiceService} from "../../services/http-service.service";
import {environment} from "../../../environments/environment";
import {mergeMap, tap} from "rxjs";
import {MatXtndSnackbarSuccessService} from "../../modules/snackbar-success";

@State<IMaintenanceStateModel>({
  name: 'maintenance',
  defaults: <IMaintenanceStateModel>{
    loading: false,
    busy: false,
    current: null,
  }
})
@Injectable()
export class MaintenanceState {

  constructor(
    private readonly http: HttpServiceService,
    private readonly snackbarSuccessService: MatXtndSnackbarSuccessService
  ) {
  }

  get baseApi() {
    return `${environment.lease_path.base_uri}` ;
  }

  @Selector()
  static IsLoading(state: IMaintenanceStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static IsWorking(state: IMaintenanceStateModel): boolean {
    return state.busy;
  }

  @Selector()
  static getCurrent(state: IMaintenanceStateModel): MaintenanceType | null {
    return state.current;
  }

  @Action(MaintenanceActions.Done)
  onDone(ctx: StateContext<IMaintenanceStateModel>) {
    ctx.patchState({
      loading: false,
      busy: false
    });
  }

  @Action(MaintenanceActions.Loading)
  onLoading(ctx: StateContext<IMaintenanceStateModel>) {
    ctx.patchState({
      loading: true
    });
  }

  @Action(MaintenanceActions.Working)
  onWorking(ctx: StateContext<IMaintenanceStateModel>) {
    ctx.patchState({
      busy: true
    });
  }

  @Action(MaintenanceActions.UploadDocument)
  onUploadDocument(ctx: StateContext<IMaintenanceStateModel>, action: MaintenanceActions.UploadDocument) {
    return ctx.dispatch(new MaintenanceActions.Working()).pipe(
      mergeMap(() => this.http.post(`${this.baseApi}document`, action.request)),
      tap(() => {
        this.snackbarSuccessService.Open('Document uploaded successfully');
        ctx.dispatch(new MaintenanceActions.Done());
      })
    );
  }


}
