import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {Loader} from 'google-maps';
import {environment} from "../../../../../environments/environment";
import {GeoTabMapPingType} from "@states/geotab";

@Component({
  selector: 'geo-tab-map-ping',
  templateUrl: 'geo-tab-map-ping.component.html',
  styleUrls: [`geo-tab-map-ping.component.scss`]
})
export class GeoTabMapPingComponent {
  static Loaded = false;
  /**
   * Map instance
   */
  map!: google.maps.Map;
  /**
   * Marker instance
   */
  markers: google.maps.Marker[] = [];

  /**
   * Input location handler
   */
  @Input() set locations(locations: Array<{ lat: number, lng: number, title: string }> | Array<GeoTabMapPingType>) {
    if (GeoTabMapPingComponent.Loaded) {
      return;
    }

    if (locations && locations.length) {
      GeoTabMapPingComponent.Loaded = true;
      this.loadLocation(locations);
    }
    setTimeout(() => {
      GeoTabMapPingComponent.Loaded = false;
    }, 1000);
  }

  /**
   * Map element reference
   */
  @ViewChild('map') mapElement!: ElementRef;

  /**
   * Loads the map for the first time
   */
  async loadMap(): Promise<void> {
    const loader = new Loader(environment.gmKey);
    const google = await loader.load();
    this.map = new google.maps.Map(this.mapElement.nativeElement, {zoom: 5});
  }

  /**
   * Checks if the map is loaded, then loads a specific location
   * @param location lat, long and title for the location
   */
  async loadLocation(locations: { lat: number, lng: number, title: string }[]) {
    // Init map if necessary
    if (!this.map) {
      await this.loadMap();
    }
    // Clear the previous markers
    this.markers.forEach(m => m.setMap(null));
    this.markers = [];
    // Create the new markers and add them to the map
    this.markers = locations.reduce((result, next) => {
      return [...result, new google.maps.Marker({
        position: {lat: next.lat, lng: next.lng},
        title: next.title,
        map: this.map
      })];
    }, [] as google.maps.Marker[]);
    // Centering the maps around the markers
    const bounds = new google.maps.LatLngBounds();
    locations.forEach((marker) => {
      bounds.extend(new google.maps.LatLng(marker.lat, marker.lng))
    });
    this.map.fitBounds(bounds);
    // Manage zoom for only 1 marker case
    google.maps.event.addListenerOnce(this.map, 'bounds_changed', () => {
      if (locations.length === 1) {
        this.map.setZoom(14);
      }
    });
  }
}
