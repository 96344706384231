<h1 class="form-title">
  Quarterly Maintenance Requirements
</h1>

<div class="row">

  <div class="col-md-12 mt-4">
    <div class="m-r-container">
      <mat-icon class="m-r-left-icons orange" fontIcon="oil_barrel"></mat-icon>
      <p class="drs-p">
        <b>Proof of a Quarterly Oil Change and Chassis Lube</b>
        <br />
        This assists us in verifying that the vehicle is well-maintained and <br />
        that any potential issues are addressed promptly.
      </p>
    </div>
  </div>

  <div class="col-md-12 mt-4">
    <div class="m-r-container">
      <mat-icon class="m-r-left-icons orange" fontIcon="receipt_long"></mat-icon>
      <p class="drs-p">
        <b>Proof of a Quarterly Oil Change and Chassis Lube</b>
        <br />
        This assists us in verifying that the vehicle is well-maintained and <br />
        that any potential issues are addressed promptly.
      </p>
    </div>
  </div>

</div>

<div class="form-content">
  <div class="row justify-content-md-center">
    <div class="col-md-12">

      <div class="row">

        <div class="col-lg-12">
          <p class="paragraph-tag">
            Please forward a picture, screenshot, or scanned work order confirming the completion to
            maintenance@otrleasing.com.
          </p>
        </div>
        <div class="col-lg-6 col-md-8 col-sm-12 mt-2">
          <mat-label>Lease</mat-label>
          <mat-form-field class="example-full-width">
            <mat-select [formControl]="leaseControl" (selectionChange)="onLeaseChange($event.value)">
              <mat-option *ngFor="let option of LeaseList" [value]="option.key">{{ option.value }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-12 mt-2">
          <p class="">
            <b>Next due date:</b>
            <ng-container *ngIf="(maintenance$ | async) as maintenance">
              {{ formatDate(maintenance.dueDate) }}
            </ng-container>
          </p>
        </div>

        <div class="col-lg-12">
          <p class="">
            Submit maintenance documentation
          </p>
        </div>

        <div class="col-lg-12 mt-3">
          <form class="example-form" (ngSubmit)="onSubmit()">

            <div class="row">
              <div class="col col-lg-12 col-xl-12">
                <div class="drop-containerr">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <div class="file-input-container">
                        <img src="assets/images/doc-drag-area.png" role="button" (click)="triggerFileInput($event)" class="mb-3 preview-image w-100">
                        <input accept=".pdf,.png,.jpeg,.jpg,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                               type="file" #fileInput id="fileInput" class="file-input" (change)="onFileSelected($event)">
                      </div>
                      <div *ngFor="let file of documents; let i = index">
                        <p class="file-link">
                          <span>{{ file.file?.name }}</span>
                          <mat-icon (click)="removeUploadedFile($event, file.file?.name)">cancel</mat-icon>
                        </p>
                      </div>
                      <form class="example-form" (ngSubmit)="onSubmit()">
                      <div class="btn-submit">
                        <button mat-stroked-button color="primary" class="w-100">Submit Documents</button>
                      </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </form>
        </div>


      </div>

    </div>
  </div>
</div>
