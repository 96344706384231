export const environment = {
  auth0:{
    domain: 'otrleasing.us.auth0.com',
    clientId: 'dXOkc1BL9K6n8bYfqvH3PeWzaGgfRUHI',
    audience: 'https://otrleasing-api',
    redirect_uri: 'http://localhost:4200/login',
    gmKey: 'AIzaSyAeW6fH15eawB1RsImAvd7PsG9XOPXxy-s',
  },
  lease_path: {
    base_uri: 'https://dev.api.otrleasing.com/api/',
    key: 'b3RyTGVzaW5nU2VydmljZVVzZXI6VyEzM1NlcnZpY2VAcHA=',
    env: 'dev'
  },
  gmKey: 'AIzaSyAeW6fH15eawB1RsImAvd7PsG9XOPXxy-s',
};
