<h1 class="form-title">
  Insurance Requirements
</h1>

<div class="form-content">

  <div class="row justify-content-md-center">
    <div class="col-md-12 mt-3">

      <table class="table table-bordered">
        <thead>
          <tr>
            <th colspan="2">Lease Required</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Auto Liability (carrier or own authority) and Non-Trucking Liability</td>
            <td>Auto Liability (carrier or own authority) and Non-Trucking Liability</td>
          </tr>
          <tr>
            <td>Physical Damage/ Full Coverage w/ Comp and Collision</td>
            <td>
              ACV or Stated Value: <br>
              Minimum $80,000 if Model Year is 3 Years old or Newer  <br>
              Minimum $70,000 if Model Year is 4 Years old <br>
              Minimum $60,000 if Model Year is 5 Years and older <br>
              $2,500 maximum deductible
            </td>
          </tr>
          <tr>
            <td>Additional Insured</td>
            <td>
              OTR Leasing, LLC <br>
              PO Box 25934 <br>
              Overland Park, KS 66225
            </td>
          </tr>
          <tr>
            <td>Loss Payee</td>
            <td>
              OTR Leasing, LLC <br>
              PO Box 25934 <br>
              Overland Park, KS 66225
            </td>
          </tr>
          <tr>
            <td>Insured Name</td>
            <td>Lessee Must be Listed as Insured</td>
          </tr>
          <tr>
            <td>Further Insurance Requirements</td>
            <td>
              Above coverage is required at all times throughout the lease period. <br>
              All insurance policies must have correct addresses at all times. <br>
              Any changes must be provided to Insurance Administrator
            </td>
          </tr>
        </tbody>
      </table>

    </div>

    <div class="col-md-12 mt-4">

      <table class="table table-bordered">
        <thead>
          <tr>
            <th colspan="2">Insurance Administrator</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Berkshire Risk</td>
            <td>
              PO Box 25934 <br>
              Overland Park, KS 66225 <br />

              <a class="d-l-link" (click)="openDialog()">Submit COI to Berkshire</a>
              <!--<input accept=".pdf,.png,.jpeg,.jpg"
                     id="file-input"
                     name="file-input"
                     type="file" #fileInput id="fileInput" class="file-input hidden"
                     (change)="onFileSelected($event)">-->


            </td>
          </tr>
          <tr>
            <td>*all insurance compliance issues should be directed to</td>
            <td>
              Phone - <a class="d-l-link" href="tel:(888)277-5270">(888)277-5270</a> <br />
              Fax - (913) 433-7150 <br />
              E-Mail - <a class="d-l-link" href="mailto:otrleasing@berkshirerisk.com">otrleasing@berkshirerisk.com</a>
            </td>
          </tr>
        </tbody>
      </table>

    </div>

  </div>

</div>

<ng-template #submitCoiDocumentTemplate>
  <!--<div class="container">

    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <h4>Submit COI to Berkshire</h4>
      </div>
    </div>

    <div class="row coi-document-content">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

        <div *ngFor="let file of documents; let i = index">
          <div class="file-link">
            <span>{{ file.file?.name }}</span>
            <span class="flex-expand"></span>
            <mat-icon class="icon" (click)="removeUploadedFile($event, file.file?.name)">cancel</mat-icon>
          </div>
        </div>

      </div>
    </div>

    <div class="row mt-3 mb-3">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <button mat-stroked-button color="primary" type="button" class="blue-btn me-4" (click)="onCloseClick()">Close</button>
        <button *ngIf="isEmptyAttachDocument" mat-stroked-button color="primary" type="button" class="float-end ms-3" (click)="triggerFileInput($event)">Add Document</button>
        <button *ngIf="!isEmptyAttachDocument" mat-stroked-button color="primary" type="button" (click)="submit()" class="float-end">Submit</button>
      </div>
    </div>

  </div>-->
</ng-template>
