<input accept=".pdf,.png,.jpeg,.jpg"
       id="file-input"
       name="file-input"
       type="file" #fileInput id="fileInput" class="file-input hidden"
       (change)="onFileSelected($event)">

<form [formGroup]="form" class="form" (ngSubmit)="submit()">
  <div class="container">

    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <h4>Submit COI to Berkshire</h4>
        <h4 *ngIf="this.data?.leaseId" class="coi-leaseid-text orange">Lease {{this.data?.leaseId}}</h4>
      </div>
    </div>

    <div class="row coi-document-content">

      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="!this.data?.leaseId">
        <mat-label>Lease <span class="required-lable-txt">(Required)</span></mat-label>
        <mat-form-field class="example-full-width">
          <mat-select formControlName="lease">
            <mat-option *ngFor="let option of leases$ | async" [value]="option">{{ option.leaseId }}</mat-option>
          </mat-select>
          <mat-error>{{getErrorMessage("lease")}}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="isEmptyAttachDocument">
        <div class="file-input-container" (click)="triggerFileInput($event)">
          <label class="file-input__label" for="file-input">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="upload" class="svg-inline--fa fa-upload fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path fill="currentColor" d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path>
            </svg>
            <span>Click here to upload a file</span>
          </label>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

        <div *ngFor="let file of documents; let i = index">
          <div class="file-link">
            <span>{{ file.file?.name }}</span>
            <span class="flex-expand"></span>
            <mat-icon class="icon" (click)="removeUploadedFile($event, file.file?.name)">cancel</mat-icon>
          </div>
        </div>

      </div>
    </div>

    <div class="row mt-3 mb-3">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <button mat-stroked-button color="primary" type="button" class="blue-btn me-4" (click)="onClose()">Close</button>
        <!--<button *ngIf="isEmptyAttachDocument" mat-stroked-button color="primary" type="button" class="float-end ms-3" (click)="triggerFileInput($event)">Add Document</button>
        <button *ngIf="!isEmptyAttachDocument" mat-stroked-button color="primary" type="button" (click)="submit()" class="float-end">Submit</button>-->
        <button [disabled]="isEmptyAttachDocument" mat-stroked-button color="primary" type="submit" class="float-end">Submit</button>
      </div>
    </div>

  </div>
  </form>
