import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import {MatXtnSnackbarSuccessModule} from "../snackbar-success";
import {AllSharedComponents} from "./components";

@NgModule({
  declarations: [
    ...AllSharedComponents()
  ],
  imports: [
    CommonModule,
    MaterialModule,
    MatXtnSnackbarSuccessModule
  ],
  exports: [
    ...AllSharedComponents(),
  ]
})
export class SharedModule { }
