import { Inject, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {GeoTabTelematicType} from "@states/geotab";

export type GeotabTelematicDialogParams = { title: string; items: GeoTabTelematicType[] }
export type GeotabTelematicDialogResponse = {}

@Component({
  selector: 'geotab-telematic-dialog',
  templateUrl: 'geotab-telematic-dialog.component.html',
  styleUrls: [`geotab-telematic-dialog.component.scss`]
})
export class GeotabTelematicDialogComponent implements OnInit {

  dataSource = new MatTableDataSource<GeoTabTelematicType>();
  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  columns = ['created', 'code', 'action'];


  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: GeotabTelematicDialogParams,
    private readonly dialogRef: MatDialogRef<GeotabTelematicDialogComponent>
  ) {
  }

  ngOnInit() {
    this.dataSource.data = this.items;
    this.dataSource.paginator = this.paginator;
  }

  get items() {
    return this.data.items;
  }

  close() {
    this.dialogRef.close();
  }


}
