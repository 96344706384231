import {Action, Select, Selector, State, StateContext, Store} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {HttpServiceService} from '../../services/http-service.service';
import {catchError, EMPTY, mergeMap, Observable, tap, throwError} from 'rxjs';
import {environment} from '../../../environments/environment';
import {LeaseAction} from './lease.actions';
import {LeaseResponseType, LeasesInnStateModel, LeaseStateModel} from './lease.model';
import {CustomerState} from '../customer/customer.state';


@State<LeasesInnStateModel>({
  name: 'lease',
  defaults: <LeasesInnStateModel>{
    loading: false,
  }
})
@Injectable()
export class LeaseState {
  baseUrl: string;

  @Select(CustomerState.GetCustomerId) customer$: Observable<string>

  constructor(
    private readonly store: Store,
    private readonly http: HttpServiceService,
  ) {
    this.baseUrl = environment.lease_path.base_uri;
  }
  @Selector()
  static IsLoading(state: LeasesInnStateModel) : boolean {
    return state.loading;
  }
  @Selector()
  static GetLeases(state: LeasesInnStateModel) : Array<LeaseResponseType> {
    return state.leases;
  }
  @Action(LeaseAction.Loading)
  onLoading(ctx: StateContext<LeasesInnStateModel>) {
    ctx.patchState({
      loading: true
    });
  }
  @Action(LeaseAction.Done)
  onDone(ctx: StateContext<LeasesInnStateModel>) {
    ctx.patchState({
      loading: false
    });
  }
  @Action(LeaseAction.GetData)
  onGetLeaseData(ctx: StateContext<LeaseStateModel>, {leaseId}: { leaseId: string }) {

    this.customer$.subscribe(id => {

      if (id == undefined)
        return;

      //Lease Api
      const url = `${this.baseUrl}Customers/${id}/leases`;
      return this.http.getDataWithToken(url).pipe(
        tap((response: LeaseStateModel[]) => {
          var lease = response.find(x => x.leaseId === leaseId);
          ctx.patchState({
            lease: lease
          });
        }),
        catchError((error: any) => {
          console.error('Error while fetching lease api:', error);
          return throwError(error);
        })
      ).subscribe();

    });


  }


  @Action(LeaseAction.GetLeasesData)
  onGetLeasesData(ctx: StateContext<LeasesInnStateModel>) {
    const customerId = this.store.selectSnapshot(CustomerState.GetCustomerId);
    console.log('customerId', customerId);
    if (customerId) {
      const url = `${this.baseUrl}Customers/${customerId}/leases`;
      return ctx.dispatch(new LeaseAction.Loading()).pipe(
        mergeMap(() => this.http.get<Array<LeaseResponseType>>(url)),
        tap((response) => {
          ctx.patchState({
            leases: response
          });
          ctx.dispatch(new LeaseAction.Done());
        }),
      )
    }
    return EMPTY;

  }
}
