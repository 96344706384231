import {Action, NgxsOnInit, Selector, State, StateContext, Store} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {HttpServiceService} from '../../services/http-service.service';
import {environment} from '../../../environments/environment';
import {catchError, map, mergeMap, tap, throwError} from 'rxjs';
import {OtrTokenActions} from './otr-token.actions';
import {ApplicationTokenResponseType, IOtrTokenModel} from './otr-token.model';
import {ContactState} from "@states/contact/contact.state";


@State<IOtrTokenModel>({
  name: 'otrToken',
  defaults: <IOtrTokenModel>{
    loading: true,
  }
})
@Injectable()
export class OtrTokenState implements NgxsOnInit {

  baseUrl: string;
  authorizedKey: string;

  constructor(
    private http: HttpServiceService,
    private store: Store) {
    this.baseUrl = environment.lease_path.base_uri;
    this.authorizedKey = environment.lease_path.key;
  }

  get baseApiUrl(): string {
    return `${this.baseUrl}Token`;
  }

  ngxsOnInit(ctx: StateContext<any>): void {
    this.store.dispatch(new OtrTokenActions.OtrLeasingToken());
  }

  @Selector()
  static getOtrToken(state: IOtrTokenModel): string {
    return state.applicationToken;
  }

  @Selector()
  static IsLoading(state: IOtrTokenModel): boolean {
    return state.loading;
  }

  @Action(OtrTokenActions.Loading)
  onLoading(ctx: StateContext<IOtrTokenModel>) {
    ctx.patchState({
      loading: true
    });
  }

  @Action(OtrTokenActions.Done)
  onDone(ctx: StateContext<IOtrTokenModel>) {
    ctx.patchState({
      loading: false
    });
  }

  @Action(OtrTokenActions.OtrLeasingToken)
  getOtrLeasingToken(ctx: StateContext<IOtrTokenModel>) {
    return ctx.dispatch(new OtrTokenActions.Loading()).pipe(
      mergeMap(() => this.http.post<ApplicationTokenResponseType>(this.baseApiUrl, {authorizedKey: this.authorizedKey})),
      tap((response: ApplicationTokenResponseType) => {
          ctx.patchState({
            applicationToken: response.applicationToken
          });
          ctx.dispatch(new OtrTokenActions.Done());
        }
      ),
    )
  }
}
