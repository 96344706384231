
export interface IFinancialStateModel {
  loading: boolean;
  busy: boolean;
  escrow: EscrowStateModel | null
  paymentActivity: PaymentActivityStateModel[] | null
}


export class EscrowStateModel {
  id: number
  assetId: string
  leaseId: string
  pmfinType: string
  escrowAmt: number
}
export class PaymentActivityStateModel {
  id: number
  customerId: number
  assetId: string
  leaseId: string
  glcode: string
  effectiveDate: Date
  postDate: Date
  leaseFinType: string
  leaseFinPrincipal: number
  leaseFinLateFee: number
  leaseFinSalesTaxState: number
  leaseFinSalesTaxCity: number
  leaseFinResidual: number
  leaseFinDownPmtAmt: number
  leaseFinTotal: number
  pmfinType: string
  pmfinDepositAmt: number
  pmfinFeeAmt: number
  pmfinTotalAmt: number
  feeFinType: string
  feeFinFee: number
}
