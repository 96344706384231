import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Actions, ofActionSuccessful, Select, Store} from "@ngxs/store";
import {LeaseAction} from "@states/lease/lease.actions";
import {FormBuilder, Validators} from "@angular/forms";
import {LeaseState} from "@states/lease/lease.state";
import {Observable, Subject, takeUntil, tap} from "rxjs";
import {LeaseResponseType} from "@states/lease/lease.model";
import {FileDTO} from "@states/upload-documents/upload-documents.model";
import {CommonService} from "../../../../../services/common.service";
import {MaintenanceActions, MaintenanceState} from "@states/maintenance";
import {CustomerState} from "@states/customer/customer.state";

@Component({
  selector: 'maintenance-upload',
  templateUrl: 'maintenance-upload.component.html',
  styleUrls: [`maintenance-upload.component.scss`]
})
export class MaintenanceUploadComponent implements OnInit, OnDestroy {
  @Select(LeaseState.IsLoading) loading$: Observable<boolean>;
  @Select(MaintenanceState.IsWorking) uploading$: Observable<boolean>;
  @Select(LeaseState.GetLeases) leases$: Observable<LeaseResponseType[]>;
  form = this.formBuilder.group({
    lease: [null, [Validators.required]],
    associatedDocumentTypeId: [null, [Validators.required]],
    associatedServiceDate: [null, [Validators.required]],
  });
  @ViewChild('fileInput') fileInput: ElementRef;
  selectedFiles: Array<File> = [];
  documents: Array<FileDTO> = [];
  // better if it comes from the server
  // although account for other types of documents if not in our server 👇
  documentTypes = [
    {id: 1, name: 'Oil Change'},
    {id: 2, name: 'DOT Inspection'},
    {id: 3, name: 'Other'},
  ]
  maxAllowed = new Date();
  unsubscribe$ = new Subject<void>();
  finished$ = this.actions$.pipe(
    ofActionSuccessful(MaintenanceActions.Done),
    takeUntil(this.unsubscribe$),
    tap(() => {
      this.form.reset();
      this.documents = [];
      this.selectedFiles = [];
      this.fileInput.nativeElement.value = null;
    })
  ).subscribe()

  constructor(
    private readonly store: Store,
    private readonly commonService: CommonService,
    private readonly formBuilder: FormBuilder,
    private readonly actions$: Actions,
  ) {
  }

  ngOnInit() {
    this.store.dispatch(new LeaseAction.GetLeasesData());
  }

  triggerFileInput(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.fileInput.nativeElement.click();
  }

  onFileSelected(event: any) {
    this.selectedFiles = event.target.files;
    const maxSize = 20 * 1024 * 1024; // 20MB in bytes
    for (let i = 0; i < this.selectedFiles.length; i++) {
      const file = this.selectedFiles[i];
      if (file.size > maxSize) {
        console.log('File size should be less than 20MB:', file.name);
        this.commonService.openSnackBar('File size should be less than 20MB', '', 'error');
        event.target.value = null;
        return;
      }
      console.log('Valid file:', file.name);
    }

    if (this.selectedFiles[0]) {
      this.documents = [{file: this.selectedFiles[0]}];
    }
  }

  removeUploadedFile(event: any, fileName: any, type: string = '') {
    this.documents = this.documents.filter(file => file.file?.name !== fileName);
    const target = event.target as HTMLElement;
    const parent = target.closest('.file-link');
    if (parent) parent.remove();
  }

  get valid() {
    return this.form.valid && this.documents.length > 0;
  }

  get lease() {
    return this.form.get('lease')?.value;
  }

  submit() {
    if (this.form.valid) {
      const customerId = this.store.selectSnapshot(CustomerState.GetCustomerId);
      // @ts-ignore
      const {baseUnitId} = this.lease;
      const value = this.form.getRawValue();
      const {associatedDocumentTypeId, associatedServiceDate} = value;
      // @ts-ignore
      const iso = (associatedServiceDate as Date).toISOString();

      const formData = new FormData();
      formData.append('AssociatedBaseUnitId', baseUnitId);
      // @ts-ignore
      formData.append('AssociatedDocumentTypeId', associatedDocumentTypeId);
      // @ts-ignore
      formData.append('AssociatedServiceDate', iso);
      formData.append('CustomerId', customerId);
      formData.append('File', this.selectedFiles[0]);
      this.store.dispatch(new MaintenanceActions.UploadDocument(formData));

    }

  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
