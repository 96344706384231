import {PageEvent} from "@angular/material/paginator";

export interface IGeotabStateModel {
  loading: boolean;
  busy: boolean;
  current: GeotabType | null;
  latestGeoTab: Array<GeotabType>;
  latestByLeases: Array<GeotabType>;
  paginationPingState: Partial<PageEvent>;
  pingsPage: Array<GeotabType>;
  lastDaysCalculation: { [key: string]: number } | null;
  telematics: Array<GeoTabTelematicType> ;
}
export const GeoTabApiKey = 'c687ce65ad3640398a8aca90d6667626';

const RESOURCE_ENDPOINT = 'https://integration-broker-function.azurewebsites.net/api/';

export const APIs = {
  Historical: RESOURCE_ENDPOINT + 'geotab-Historical',
  Telematics: RESOURCE_ENDPOINT + 'geotab-Telematic',
}

export const defaultPermissionKeyPayload = {
  permissionKey: "c687ce65ad3640398a8aca90d6667626"
}

export type GeotabType = {
  activeFrom: string;
  activeTo: string;
  comment: string;
  createdDate: string;
  deviceId: string;
  deviceType: string;
  id: number;
  latitude: number;
  leaseId: string | null;
  licensePlate: string;
  licenseState: string;
  longitude: number;
  name: string;
  odometer: number;
  pinDevice: boolean;
  productId: number;
  serialNumber: string;
  timeZoneId: string;
  unitNumber: string | null;
  uomOdometer: number;
  vehicleIdentificationNumber: string;
}

export type GeoTabMapPingType = {
  title: string;
  lat: number;
  lng: number;
}
export type GeoTabMileageCalcType ={
  mileage: number;
}
export type GeoTabTelematicType = {
  address: string;
  dateTime: Date | string;
  uomOdometer: number;
  uomSpeed: number;
  diagnostic: string;
  rule: string;
  code?: string;
}
