
export namespace MaintenanceActions{
  export class Loading {
    static readonly type = '[Maintenance] Set As Loading';
  }
  export class Working {
    static readonly type = '[Maintenance] Set As Working';
  }
  export class Done {
    static readonly type = '[Maintenance] Set As Done';
  }
  export class UploadDocument {
    static readonly type = '[Maintenance] Upload Document';
    constructor(public readonly request: FormData) {}
  }


}


