import {Component, Host, HostListener, Input} from '@angular/core';
import {BooleanInput, coerceBooleanProperty} from "@angular/cdk/coercion";
import {Router} from "@angular/router";

@Component({
  selector: 'geotab-logo',
  templateUrl: 'geotab-logo.component.html',
  styleUrls: [`geotab-logo.component.scss`]
})
export class GeotabLogoComponent {
  private actAsLink = true;
  @Input() leaseId!: string | null;
  @Input() set iconAsLink(value: BooleanInput) {
    this.actAsLink = coerceBooleanProperty(value)
  }
  get iconAsLink() {
    return this.actAsLink;
  }
  constructor(private readonly router: Router) { }
  @HostListener('click')
  onClick() {
    if (this.actAsLink) {
      this.router.navigate(['/geo-tab', this.leaseId]).then(r => {
        setTimeout(() => location.reload())
      });
    }
  }



}
