import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Select, Store } from "@ngxs/store";
import { map, Observable } from "rxjs";
import { auth0User } from '../../states/auth/auth.model';
import { AuthState } from '../../states/auth/auth.state';
import { AuthActions } from '../../states/auth/auth.actions';
import { VisibilityService } from '../../services/visibility.service';



@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  @Select(AuthState.CurrentUser) user$: Observable<auth0User> | undefined;
  @Select(AuthState.IsDone) done$: Observable<boolean> | undefined;
  @Select(AuthState.IsUserInactive) isUserInactive$: Observable<boolean>;

  showCurrentPaymentBanner = false;
  showPastDueBanner = false;

  constructor(private readonly store: Store, private visibilityService: VisibilityService) {
  }

  ngOnInit(): void {
    this.visibilityService.currentPayment$.subscribe(
      (isVisible) => (this.showCurrentPaymentBanner = isVisible)
    );
    this.visibilityService.pastDue$.subscribe(
      (isVisible) => (this.showPastDueBanner = isVisible)
    );
  }

  logout() {
    this.store.dispatch(new AuthActions.LogOut());
  }
  login() {
    this.store.dispatch(new AuthActions.Login());
  }
}
