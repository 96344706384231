
export namespace InsuranceActions{
  export class Loading {
    static readonly type = '[Insurance] Set As Loading';
  }
  export class Working {
    static readonly type = '[Insurance] Set As Working';
  }
  export class Done {
    static readonly type = '[Insurance] Set As Done';
  }
  export class UploadDocument {
    static readonly type = '[Insurance] Upload Document';
    constructor(public readonly request: FormData) {}
  }


}


